import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi this is Fraser.`}</p>
    <p>{`I'm a web developer from China, now living and working in Melbourne. I'm currently working as a lead engineer for `}<a parentName="p" {...{
        "href": "https://envato.com"
      }}>{`Envato`}</a>{`.`}</p>
    <p>{`The main project I'm working on now is `}<a parentName="p" {...{
        "href": "https://elements.envato.com/"
      }}>{`Envato Elements`}</a>{`, before that I also worked on `}<a parentName="p" {...{
        "href": "https://milkshake.app/"
      }}>{`Milkshake`}</a>{` and `}<a parentName="p" {...{
        "href": "https://themeforest.net"
      }}>{`Envato Market`}</a>{`.`}</p>
    <p>{`This website is built with `}<a parentName="p" {...{
        "href": "http://gatsbyjs.org/"
      }}>{`Gatsbyjs`}</a>{` and hosted on `}<a parentName="p" {...{
        "href": "https://netlify.com/"
      }}>{`Netlify`}</a>{`, I migrated my previous blog site from a Jekyll based staic site on `}<a parentName="p" {...{
        "href": "https://fraserxu.me"
      }}>{`fraserxu.me`}</a>{` to this one on `}<a parentName="p" {...{
        "href": "https://fraserxu.dev"
      }}>{`fraserxu.dev`}</a>{`. This site might be over engineered but I mainly want to use it to learn and play with different technologies that may or not use in work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      